import barquito1 from 'static/images/illustrations/barquito-1.svg';
import barquito2 from 'static/images/illustrations/barquito-2.svg';
import barquito3 from 'static/images/illustrations/barquito-3.svg';

const smoothList = [
  {
    id: 0,
    slug: 'design-and-build',
    subTitle: 'Get the best of both worlds',
    title: 'DESIGN & BUILD',
    description:
      "Functionality vs design? Trade-offs can be tricky. That's why we help you keep feature creep at bay. Get support defining your product architecture and customizing your existing design libraries, for a balanced MVP that delivers on your vision.",
    image: barquito1,
    altImage: 'A paper boat',
    useSVG: true,
  },
  {
    id: 1,
    slug: 'design-and-build',
    subTitle: 'Hit the market running',
    title: 'LAUNCH & LEARN',
    description:
      'In the tech world, speed wins. We combine agile methodologies with a limited scope to get you a streamlined product in 90 days. Accelerate your time-to-market, so you can start signing users and get the validation you need — stat!',
    image: barquito2,
    altImage: 'A wood boat',
    useSVG: true,
  },
  {
    id: 2,
    slug: 'design-and-build',
    subTitle: 'Build for growth from the start',
    title: 'SCALE & ITERATE',
    description:
      "An MVP isn't a one-hit-wonder or a half-baked solution. Because this is just the first step for your product, we architect it in a way that makes future development easy. Giving you a solid foundation to build iteratively, and scale with ease.",
    image: barquito3,
    altImage: 'A cargo ship',
    useSVG: true,
  },
];

export default smoothList;
