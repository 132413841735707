import React from 'react';
import MetaTags from 'core/meta-tags/index';
import theme from 'styles/theme';
import { graphql } from 'gatsby';
import Link from 'atomic-design/atoms/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from 'common/string-helpers';

import Clients from 'atomic-design/organisms/clients';
import PageSection from 'atomic-design/atoms/page-section';
import Layout from 'core/layout';
import PageHeader from 'atomic-design/atoms/page-header';
import Button from 'atomic-design/atoms/button';
import ListCircleImages from 'atomic-design/organisms/list-circle-images';
import mvpDevelopmentListSection from 'domain/mvpDevelopmentListSection';
import Tshaped from 'atomic-design/organisms/t-shaped';
import MainContainer from 'atomic-design/atoms/main-container';
import { MVPDevelopmentList } from 'domain/TShapedLists';
import CallToActionDiagonal from 'atomic-design/organisms/call-to-action-diagonal';
import TechnologyCard from 'atomic-design/molecules/technology-card';
import BuiltSection from 'atomic-design/organisms/built-section';
import CallToAction from 'atomic-design/molecules/call-to-action';
import TshapedImage from 'static/images/icons/T-shaped-mvp.svg';
import AgileInfoItem from 'atomic-design/molecules/agile-info-item';
import ImgAndInformation from 'atomic-design/molecules/img-and-information';
import smoothList from 'domain/smooth-list';

import {
  PageHeaderContainer,
  TestimonialContainer,
  TestimonialBackground,
  CallToActionTshaped,
  CallToActionContent,
  TshapedContainer,
  ClientsSection,
  ClientsBackground,
  ClientsContainer,
  TechnologyTitle,
  TechnologyText,
  TechnologyList,
  TechnologyItem,
  SmoothItem,
  SmoothList,
  SmoothHeader,
} from 'styles/pages/mvp-development-styles';
import VideosAndInformation from '../../components/atomic-design/molecules/videos-and-information';
import { TestimonialVideosContainer } from '../../styles/pages/mvp-development-styles';

export const MVPDevelopmentQuery = graphql`
  query MVPQuery {
    allContentfulPage(filter: { url: { eq: "services/mvp-development" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
    allContentfulTestimonialFeatured(filter: { pageId: { eq: "MVPDevelopment" } }) {
      edges {
        node {
          description {
            description
          }
          author {
            image {
              contentful_id
            }
            name
            role
          }
        }
      }
    }
    allContentfulClient(
      filter: { pagesIn: { elemMatch: { url: { eq: "services/mvp-development" } } } }
      sort: { fields: order }
    ) {
      edges {
        node {
          companyName
          website
          logo {
            contentful_id
            title
          }
          pagesIn {
            url
          }
          order
        }
      }
    }
    allContentfulCaseStudy(
      filter: { pageId: { eq: "MVPDevelopment" } }
      sort: { fields: publishedDate, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          title
          slug
          LimitedAbstract
          thumbnail {
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulTechnology(
      filter: { pagesIn: { elemMatch: { url: { eq: "services/mvp-development" } } } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          name
          imageSvg {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulTestimonialVideo(
      filter: { pages: { elemMatch: { url: { eq: "services/mvp-development" } } } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          video
          quote {
            quote
          }
        }
      }
    }
  }
`;

const MVPDevelopment = ({ data, location }) => {
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const testimonialFeatured = data.allContentfulTestimonialFeatured.edges[0].node;
  const caseStudiesData = data.allContentfulCaseStudy.edges;
  const clients = data.allContentfulClient.edges;
  const technologies = data.allContentfulTechnology?.edges;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;
  const testimonialVideos = data.allContentfulTestimonialVideo?.edges;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />

      <PageSection margin={`0 0 ${theme.newTheme.spacing(15)} 0`}>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
            color={theme.newTheme.color.service.mvpDevelopment.main}
          />
        </PageHeaderContainer>
        <Link to="/contact" rel="noreferrer">
          <Button variant="contained">Build my MVP</Button>
        </Link>
      </PageSection>

      <TestimonialContainer>
        <TestimonialBackground />
        <PageSection background={theme.newTheme.color.service.mvpDevelopment.background}>
          <TestimonialVideosContainer>
            {testimonialVideos.map(testimonialVideo => (
              <VideosAndInformation
                video={testimonialVideo.node.video}
                text={testimonialVideo.node.quote.quote}
              />
            ))}
          </TestimonialVideosContainer>
        </PageSection>
      </TestimonialContainer>

      <PageSection margin={`${theme.newTheme.spacing(10)} 0 0 0}`}>
        <SmoothHeader>
          <AgileInfoItem
            title={[<span>Smooth sailing</span>, 'from start to finish']}
            description="Great ideas shouldn't be painful to execute. With 8+ years' developing MVPs, we can make helpful recommendations on what design and features will best fit your goals. Keeping you on time, and on budget."
          />
        </SmoothHeader>
        <SmoothList>
          {smoothList.map((item, index) => (
            <SmoothItem key={item.id}>
              <ImgAndInformation
                subTitle={item.subTitle}
                title={item.title}
                description={item.description}
                image={item.image}
                altImage={item.altImage}
                leftImage={index % 2 === 0}
                useSVG={item.useSVG}
              />
            </SmoothItem>
          ))}
        </SmoothList>
      </PageSection>

      <ClientsSection>
        <ClientsBackground />
        <PageSection
          padding={`${theme.newTheme.spacing(30)} 0 ${theme.newTheme.spacing(20)}`}
          background={theme.newTheme.color.service.mvpDevelopment.background}
        >
          <ClientsContainer>
            <Clients clients={clients} />
          </ClientsContainer>
        </PageSection>
      </ClientsSection>

      <PageSection margin={`0 0 ${theme.newTheme.spacing(15)} 0`}>
        <ListCircleImages
          title={mvpDevelopmentListSection.title}
          description={mvpDevelopmentListSection.description}
          buttonText={mvpDevelopmentListSection.buttonText}
          colorList={mvpDevelopmentListSection.color}
          list={mvpDevelopmentListSection.list}
        />
      </PageSection>

      <PageSection
        background={theme.newTheme.color.service.mvpDevelopment.background}
        padding={`${theme.newTheme.spacing(12)} 0`}
        containerSize="fluid"
      >
        <MainContainer>
          <Tshaped
            cardArray={MVPDevelopmentList}
            showRoles={false}
            subtitle="Our agile MVP squads:"
            checkIconColor={theme.newTheme.color.service.mvpDevelopment.main}
          />
        </MainContainer>
        <CallToActionTshaped>
          <CallToActionContent>
            <MainContainer>
              <h6>Get to know us better?</h6>
              <Link to="/about-us" rel="noreferrer">
                <Button variant="contained">Meet the team</Button>
              </Link>
            </MainContainer>
          </CallToActionContent>
          <TshapedContainer>
            <img src={TshapedImage} alt="T-shaped" />
          </TshapedContainer>
        </CallToActionTshaped>
      </PageSection>

      <PageSection padding={`${theme.newTheme.spacing(5)} 0`}>
        <TechnologyTitle>In tune with your technology</TechnologyTitle>
        <TechnologyText>
          Front-end, back-end, DevOps and Cloud. We work with the right tools and frameworks for
          your business.
        </TechnologyText>

        <TechnologyList>
          {technologies?.map(technology => (
            <TechnologyItem key={technology.node.name}>
              <TechnologyCard
                title={technology.node.name}
                altImage={technology.node.name}
                image={technology.node.imageSvg.file.url}
              />
            </TechnologyItem>
          ))}
        </TechnologyList>
      </PageSection>

      <PageSection padding={`${theme.newTheme.spacing(25)} 0`}>
        <BuiltSection
          caseStudies={caseStudiesData}
          titleColor={theme.newTheme.color.service.mvpDevelopment.main}
        />
        <CallToAction
          sectionTitle="There's more where that came from."
          buttonTitle="Take a look at our work "
          to="/work"
        />
      </PageSection>

      <CallToActionDiagonal
        background={theme.newTheme.color.gradient.background.primary.main}
        padding={`${theme.newTheme.spacing(16)} 0`}
        title="Ready to launch your MVP?"
        description="Whether it's for a question or a quote, we'd love to hear from you"
        buttonText="Let's talk"
      />
    </Layout>
  );
};

MVPDevelopment.propTypes = {};

MVPDevelopment.defaultProps = {};

export default MVPDevelopment;
