import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newTheme.spacing(12)};
  justify-content: center;
  ${({ leftImage }) => (leftImage ? 'flex-direction: row' : 'flex-direction: row-reverse')};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-direction: column;
  }
  h2 {
    color: ${({ theme }) => `${theme.newTheme.color.primary.main}`};
    font-size: ${({ theme }) => `${theme.newTheme.font.title2.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title2.lineHeight}`};
  }
`;

export const ImgContainer = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const InformationContainer = styled.div`
  width: 50%;

  div {
    padding: 0;
  }

  p {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ theme }) => theme.newTheme.color.grey.main};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }

  h2 {
    font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
    width: 100%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    padding: ${({ theme }) => `${theme.newTheme.spacing(6)} 0`};

    h2 {
      font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
    }
  }
`;

export const Title = styled.h5`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  margin: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const Subtitle = styled.h6`
  width: 100%;
  font-size: ${({ theme }) => theme.newTheme.font.title4.size};
  line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  margin: ${({ theme }) => `${theme.newTheme.spacing(8)} 0`};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  }
`;
