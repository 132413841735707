import styled from 'styled-components';

export const Card = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.newTheme.spacing(10)};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};
  background-color: ${({ theme }) => theme.newTheme.color.service.mvpDevelopment.background};
  justify-content: space-between;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.newTheme.spacing(3)};
    justify-content: space-around;
}

  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  h6 {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
    color: ${({ theme }) => theme.newTheme.color.service.mvpDevelopment.main};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 55%;
`;
