import styled, { css } from 'styled-components';

export const PageHeaderContainer = styled.section`
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};
  b {
    display: block;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const TestimonialContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.newTheme.spacing(12)} 0;
`;

export const TestimonialBackground = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-right: 100vw solid transparent;
`;

export const CallToActionTshaped = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ theme }) => theme.newTheme.spacing(16)} 0;
  h6 {
    margin: ${({ theme }) => theme.newTheme.spacing(2)} 0;
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title5.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    }
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.newTheme.spacing(6)} 0;
  }
`;

export const CallToActionContent = styled.div`
  z-index: 9;
  position: relative;
  width: 100%;
`;

export const TshapedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(4)};
  img {
    width: 100%;
  }
`;

export const ClientsSection = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.newTheme.spacing(12)} 0;
`;

export const ClientsBackground = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-right: 100vw solid transparent;
`;

export const ClientsContainer = styled.div`
  position: relative;
  z-index: 9;
  div {
    padding: 0;
  }
`;

export const TechnologyTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.newTheme.color.service.mvpDevelopment.main};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  }
`;

export const TechnologyText = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  width: 50%;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(25)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(15)};
  }
`;

export const TechnologyList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.newTheme.spacing(4)};
  justify-content: space-between;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    gap: 0;
  }
`;

export const TechnologyItem = styled.li`
  width: ${({ theme }) => theme.newTheme.spacing(54)};
  height: ${({ theme }) => theme.newTheme.spacing(54)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 50%;
    height: ${({ theme }) => theme.newTheme.spacing(39)};
    padding: ${({ theme }) => theme.newTheme.spacing(2)};
  }
`;

export const SmoothList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const SmoothItem = styled.li`
  margin-bottom: ${({ theme }) => `${theme.spacing.long}`};
  padding: ${({ theme }) => theme.newTheme.spacing(15)};

  img {
    width: 80%;
  }

  h6 {
    color: ${({ theme }) => theme.newTheme.color.service.mvpDevelopment.main};
  }

  p {
    margin: 0;
  }

  &:nth-child(2) {
    background: ${({ theme }) => theme.newTheme.color.service.mvpDevelopment.background};
    border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      padding-top: ${({ theme }) => theme.newTheme.spacing(10)};
      padding-bottom: ${({ theme }) => theme.newTheme.spacing(2)};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(8)};
    padding: ${({ theme }) => `0 ${theme.newTheme.spacing(5)}`};
  }
`;

export const SmoothHeader = styled.div`
  h4 {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    color: ${({ theme }) => theme.newTheme.color.black};

    span {
      display: block;
      font-size: ${({ theme }) => theme.newTheme.font.title3.size};
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    width: 85%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};

      span {
        display: block;
        font-size: ${({ theme }) => theme.newTheme.font.title4.size};
        line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      }
    }

    p {
      width: 100%;
    }
  }
`;

export const TestimonialVideosContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.newTheme.spacing(30)} 0
    ${({ theme }) => theme.newTheme.spacing(15)} 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: ${({ theme }) => theme.newTheme.spacing(11)};
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.newTheme.spacing(15)};
    margin: ${({ theme }) => theme.newTheme.spacing(15)} 0
      ${({ theme }) => theme.newTheme.spacing(15)} 0;
  }
`;
