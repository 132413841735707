import React from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import { Container, ImgContainer, InformationContainer, Subtitle, Title } from './styles';

const ImgAndInformation = ({
  subTitle,
  title,
  description,
  image,
  altImage,
  leftImage,
  useSVG,
}) => {
  return (
    <Container leftImage={leftImage}>
      <ImgContainer>
        {useSVG ? (
          <img src={image} alt={altImage} />
        ) : (
          <Image alt={altImage} partialPathName={image} width="100%" fluid />
        )}
      </ImgContainer>
      <InformationContainer leftImage={leftImage}>
        <Title>{title}</Title>
        <Subtitle>{subTitle}</Subtitle>
        <p>{description}</p>
      </InformationContainer>
    </Container>
  );
};

ImgAndInformation.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.element,
  altImage: PropTypes.string,
  leftImage: PropTypes.bool,
  useSVG: PropTypes.bool,
};

ImgAndInformation.defaultProps = {
  subTitle: '',
  title: '',
  description: '',
  image: '',
  altImage: '',
  leftImage: true,
  useSVG: false,
};

export default ImgAndInformation;
