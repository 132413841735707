import React from 'react';

import theme from 'styles/theme';
import mvp1 from 'static/images/icons/1-mvp-develop.svg';
import mvp2 from 'static/images/icons/2-mvp-develop.svg';
import mvp3 from 'static/images/icons/3-mvp-develop.svg';
import mvp4 from 'static/images/icons/4-mvp-develop.svg';

const mvpDevelopmentListSection = {
  title: (
    <>
      Your MVP launch in
      <span> 4 simple steps</span>
    </>
  ),

  description: (
    <>Get your startup off the starting blocks with a process that's engineered to deliver.</>
  ),
  buttonText: 'Start your MVP journey',
  color: theme.newTheme.color.service.mvpDevelopment.main,
  list: [
    {
      image: mvp1,
      alt: 'number 1',
      title: 'Meet',
      description:
        "Every collaboration starts with a quick chat to understand your needs, challenges and vision. And to make sure we're the best fit. We assess your project scope and identify the best opportunities.",
    },
    {
      image: mvp2,
      alt: 'number 2',
      title: 'Plan',
      description:
        "Specialists don't rush in without a strategy. We define your high-level technical architecture and hand-pick your dream team. Then present our action plan to help you reach your goals.",
    },
    {
      image: mvp3,
      alt: 'number 3',
      title: 'Develop',
      description:
        'This is where the magic happens. Working in collaborative sprints, we develop and test your product. Agile methodologies help us stay flexible as we build. Keeping your project flowing faster and delivering a fully-functional product.',
    },
    {
      image: mvp4,
      alt: 'number 4',
      title: 'Launch',
      description:
        "Time to fly! Once you're happy with the final iteration, we deploy your new digital product! It's not over after delivery though. We support you in validating user feedback and implementing changes to ensure a friction-free release.",
      isLast: true,
    },
  ],
};

export default mvpDevelopmentListSection;
