import React from 'react';
import PropTypes from 'prop-types';

import { Card, TitleContainer, Img } from './styles';

const TechnologyCard = ({ title, altImage, image }) => {
  return (
    <Card>
      <Img alt={altImage} src={image} />
      <TitleContainer>
        <h6>{title}</h6>
      </TitleContainer>
    </Card>
  );
};

TechnologyCard.propTypes = {
  title: PropTypes.string.isRequired,
  altImage: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
};

export default TechnologyCard;
