import React from 'react';
import { QuoteIcon } from 'ui/svg';
import { Container, Video, InformationContainer, Information, QuoteContainer } from './styles';

const VideosAndInformation = ({ video, text }) => {
  return (
    <Container>
      <Video src={video} frameborder="0" allowfullscreen />
      <InformationContainer>
        <Information>
          <q>{text}</q>
        </Information>
        <QuoteContainer>
          <QuoteIcon color={'#BDBDBD'} background={'none'} size={45} />
        </QuoteContainer>
      </InformationContainer>
    </Container>
  );
};

export default VideosAndInformation;
